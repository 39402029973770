import { AgdirMapboxEnvironment, AgdirTranslationsEnvironment, Environment } from '@agdir/environment/domain';

import { SUPPORTED_LANGUAGES } from '../../libs/i18n/angular/src/SUPPORTED_LANGUAGES';

export const weHaveSharedVendors = (env: Environment) => {
	env.register(new AgdirTranslationsEnvironment({ availableLanguages: [...SUPPORTED_LANGUAGES.keys()] })).register(
		new AgdirMapboxEnvironment({
			accessToken: 'pk.eyJ1IjoiYW5kcmlzZHVkYXJldnMiLCJhIjoiY2w0bzJtcWh3MDNtdDNjbjUxYjA1c3k2ayJ9.fRLDsqkuiVmwLIsRjhNlbA',
		}),
	);
};
